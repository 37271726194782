/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SideMenu {
  display: inline-block;
  width: 40%;
}
@media (max-width: 1199px) {
  .SideMenu {
    width: 26rem;
  }
}
@media (max-width: 1199px) {
  .SideMenu {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .SideMenu > .cards {
    text-align: center;
  }
}
.SideMenu > .cards > .card {
  position: relative;
  display: inline-block;
  margin: 2rem;
  height: 20rem;
  background: #f2eee5;
  border-bottom: 0.7rem solid #e6e0d0;
  color: #745a9e;
  cursor: pointer;
  text-align: center;
  border-radius: 0.9rem;
  transition: 0.15s all;
  width: 22rem;
}
@media (max-width: 1199px) {
  .SideMenu > .cards > .card {
    margin: 0.7rem;
    width: 17rem;
    height: 17rem;
  }
}
.SideMenu > .cards > .card:hover {
  transform: scale(1.1);
}
.SideMenu > .cards > .card .name {
  font-size: 1.8rem;
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: 1.8rem;
  width: 100%;
  text-align: center;
}
.SideMenu > .cards > .card .Image {
  border: 0.4rem solid #e6e0d0;
  background: #fff;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  position: absolute;
  top: 2rem;
  left: 6rem;
  padding-top: 2.2rem;
}
@media (max-width: 1199px) {
  .SideMenu > .cards > .card .Image {
    top: 1rem;
    left: 3.5rem;
  }
}
.SideMenu > .cards > .card#SideMenuProgress img {
  margin-top: 3rem;
  margin-left: -3.3rem;
}
.SideMenu > .cards > .card#SideMenuDetails img {
  height: 9rem;
}
.SideMenu > .cards > .card#SideMenuDetails .Image,
.SideMenu > .cards > .card#SideMenuProgress .Image,
.SideMenu > .cards > .card#SideMenuFamily .Image {
  padding-top: 0.1rem;
}
.SideMenu > .cards > .selected {
  background: #745a9e;
  border-bottom: 0.7rem solid #534468;
  color: #fff;
}
.SideMenu > .cards > .selected .Image {
  border: 0.4rem solid #534468;
}
