/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HelpQuestion {
  background: #fff;
  text-align: left;
  border: 0.3rem solid #e9e3db;
  border-radius: 1rem;
  margin-top: 2rem;
  overflow: hidden;
}
@media (max-width: 767px) {
  .HelpQuestion {
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.HelpQuestion.collapsed > .question > .Image {
  transform: rotate(180deg);
}
.HelpQuestion > .question {
  cursor: pointer;
  position: relative;
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  background: #fcf9f5;
  color: #745a9e;
  padding: 1.4rem 2rem;
  line-height: 1.25;
  margin: 0;
  padding-left: 5rem;
  border-radius: 1rem;
}
@media (max-width: 767px) {
  .HelpQuestion > .question {
    font-size: 1.4rem;
  }
}
.HelpQuestion > .question > .Image {
  transition: all 0.3s;
  margin-right: 1rem;
  top: 0.8rem;
  transform: rotate(270deg);
  position: absolute;
  left: 1rem;
}
.HelpQuestion > .question > .Image div,
.HelpQuestion > .question > .Image img {
  width: 3rem !important;
  height: 3rem !important;
}
.HelpQuestion .answer {
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.7;
  padding: 2rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .HelpQuestion .answer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
}
