/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.GuaranteesBanner img {
  width: 3.4rem;
  height: 3.4rem;
  margin-right: 2rem;
}
.GuaranteesBanner__row {
  display: flex;
  margin: 4rem;
  align-items: center;
  text-align: left;
}
.GuaranteesBanner__text {
  flex: 1;
  font-weight: 600;
  color: #4a82ff;
}
.GuaranteesBanner__title {
  font-size: 1.8rem;
}
.GuaranteesBanner__message {
  font-size: 1.2rem;
}
