/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MinecraftEditionSupportSummary {
  padding: 5rem;
  background: white;
  color: #402e59;
  border-radius: 1rem;
  position: relative;
  max-width: 76rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  z-index: 5;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
  .MinecraftEditionSupportSummary {
    padding: 2rem;
    margin: 3rem;
  }
}
.MinecraftEditionSupportSummary > .header {
  font-weight: 600;
  color: #095d84;
  text-align: center;
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  .MinecraftEditionSupportSummary > .header {
    font-size: 1.6rem;
  }
}
.MinecraftEditionSupportSummary .blurb {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 6rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .MinecraftEditionSupportSummary .blurb {
    margin-top: 3rem;
  }
}
.MinecraftEditionSupportSummary .editions {
  text-align: center;
  margin-top: 5rem;
}
@media (max-width: 767px) {
  .MinecraftEditionSupportSummary .editions {
    margin-top: 3rem;
  }
}
.MinecraftEditionSupportSummary .editions .edition {
  display: inline-block;
  width: 45%;
}
.MinecraftEditionSupportSummary .editions .edition > .editionName {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}
