/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PaymentForm {
  margin: 0 auto;
  text-align: left;
  width: 100%;
  max-width: 50rem;
}
@media (max-width: 991px) {
  .PaymentForm {
    padding: 1rem;
  }
}
.PaymentForm p {
  font-weight: 600;
  text-align: center;
}
.PaymentForm .Input {
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}
.PaymentForm .Input .error {
  position: absolute;
  top: 6rem;
  width: 40rem;
  overflow: hidden;
}
.PaymentForm .CurrencyChoice {
  position: relative;
}
.PaymentForm .CurrencyChoice .currency-flag {
  position: absolute;
  top: 2rem;
  left: 6rem;
  z-index: 100;
}
.PaymentForm .CurrencyChoice select {
  border: 0;
  background: #f9f6ef;
  font-size: 1.7rem;
  font-weight: 600;
  height: 4rem;
  width: 60%;
}
.PaymentForm__errorMessage {
  color: #ff486b;
}
.PaymentForm__TabsWrapper {
  margin-top: 2rem;
}
