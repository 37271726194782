/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UpgradePlanTile {
  display: flex;
  background: #4a82ff;
  color: #fff;
  border-radius: 1rem;
  margin: 2rem 0;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.UpgradePlanTile:hover {
  transform: scale(1.1);
}
.UpgradePlanTile--theme-blue {
  background: #4a82ff;
}
.UpgradePlanTile--theme-blue:hover {
  background: #175fff;
}
.UpgradePlanTile--theme-blue.UpgradePlanTile--selected {
  color: #4a82ff;
}
.UpgradePlanTile--theme-blue.UpgradePlanTile--selected:hover {
  background: #fff;
}
.UpgradePlanTile--theme-red {
  background: #ff486b;
}
.UpgradePlanTile--theme-red:hover {
  background: #ff1542;
}
.UpgradePlanTile--theme-red.UpgradePlanTile--selected {
  color: #ff486b;
}
.UpgradePlanTile--theme-red.UpgradePlanTile--selected:hover {
  background: #fff;
}
.UpgradePlanTile--theme-green {
  background: #9cd541;
}
.UpgradePlanTile--theme-green:hover {
  background: #95d828;
}
.UpgradePlanTile--theme-green.UpgradePlanTile--selected {
  color: #67941f;
}
.UpgradePlanTile--theme-green.UpgradePlanTile--selected:hover {
  background: #fff;
}
.UpgradePlanTile--theme-purple {
  background: #614988;
}
.UpgradePlanTile--theme-purple:hover {
  background: #493767;
}
.UpgradePlanTile--theme-purple.UpgradePlanTile--selected {
  color: #614988;
}
.UpgradePlanTile--theme-purple.UpgradePlanTile--selected:hover {
  background: #fff;
}
.UpgradePlanTile--selected {
  background: #fff;
}
.UpgradePlanTile__checkbox {
  background: #fff;
  border-radius: 50%;
  width: 3.4rem;
  height: 3.4rem;
  margin: 2rem;
  transition: all 0.3s;
}
.UpgradePlanTile__checkbox img {
  width: 3.4rem;
  height: 3.4rem;
}
.UpgradePlanTile__text {
  flex: 1;
  margin: 3rem 2rem;
}
.UpgradePlanTile__title {
  font-weight: bold;
  font-size: 3.4rem;
}
.UpgradePlanTile__subtitle {
  font-weight: 600;
  font-size: 1.8rem;
}
.UpgradePlanTile__discount {
  display: inline-block;
  color: #ffdc4a;
  font-size: 2.8rem;
  margin-left: 2rem;
}
.UpgradePlanTile__image {
  align-self: flex-end;
  margin: 0 2rem;
}
.UpgradePlanTile__image img {
  height: 12.5rem;
}
@media (max-width: 991px) {
  .UpgradePlanTile {
    margin: 2rem;
  }
  .UpgradePlanTile__image {
    display: none;
  }
}
