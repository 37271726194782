/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UpgradePlans {
  margin-bottom: 10rem;
  margin-top: 5rem;
}
@media (max-width: 991px) {
  .UpgradePlans {
    margin-top: 0;
  }
}
.UpgradePlans__wrapper {
  margin: 0 auto;
  max-width: 70rem;
}
.UpgradePlans__center {
  text-align: center;
}
@media (max-width: 767px) {
  .UpgradePlans__center {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.UpgradePlans__box-row {
  display: flex;
  color: #614988;
  line-height: 2.4;
  font-weight: 600;
}
@media (max-width: 767px) {
  .UpgradePlans__box-row {
    display: block;
  }
}
.UpgradePlans__box-left {
  flex: 1;
  font-weight: 800;
}
.UpgradePlans__coupon {
  display: flex;
  background-color: #ff486b;
  color: #fff;
  border-radius: 1rem;
}
@media (max-width: 767px) {
  .UpgradePlans__coupon {
    display: block;
  }
  .UpgradePlans__coupon > img {
    width: 100%;
  }
}
.UpgradePlans__coupon-text {
  color: #fff;
}
@media (max-width: 767px) {
  .UpgradePlans__coupon-text {
    padding-bottom: 1rem;
  }
}
.UpgradePlans__coupon-text .PromoCountdown {
  padding-bottom: 1rem;
  font-size: 2.8rem;
  min-width: 25rem;
}
.UpgradePlans__coupon-text p {
  padding-left: 1rem;
  padding-right: 1rem;
}
