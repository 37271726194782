/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.TileChoices {
  width: 100%;
  text-align: center;
}
.TileChoices .TileChoice {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 35rem;
  margin: 5rem;
  transition: all 0.15s;
}
@media (max-width: 991px) {
  .TileChoices .TileChoice {
    width: 15rem;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.TileChoices .TileChoice:hover {
  transform: scale(1.1);
}
.TileChoices .TileChoice.product {
  width: 30%;
  max-width: 26rem;
  margin: 5rem 2rem;
}
@media (max-width: 991px) {
  .TileChoices .TileChoice.product {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    width: 50%;
  }
}
.TileChoices .TileChoice.product .name {
  bottom: 7rem;
  font-size: 2.2rem;
}
@media (max-width: 991px) {
  .TileChoices .TileChoice.product .name {
    bottom: 2rem;
    font-size: 1.2rem;
  }
}
.TileChoices .TileChoice#modding .name,
.TileChoices .TileChoice#roblox .name {
  display: none;
}
.TileChoices .TileChoice img {
  width: 100%;
  height: 100%;
}
.TileChoices .TileChoice .name {
  position: absolute;
  bottom: 12rem;
  left: 0;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #fff;
  font-weight: 600;
  font-size: 3.2rem;
}
@media (max-width: 991px) {
  .TileChoices .TileChoice .name {
    bottom: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 2rem;
  }
}
.TileChoices .TileChoice .description {
  position: absolute;
  top: 26rem;
  left: 0;
  width: 100%;
  padding-left: 7rem;
  padding-right: 7rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
}
@media (max-width: 991px) {
  .TileChoices .TileChoice .description {
    display: none;
  }
}
