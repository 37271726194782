/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PageHeader {
  padding-top: 3rem;
  padding-left: 2rem;
  padding-bottom: 3rem;
  margin: auto;
  color: #745a9e;
}
@media (max-width: 1199px) {
  .PageHeader {
    text-align: center;
    padding-left: 0;
  }
}
.PageHeader__title {
  font-size: 4rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .PageHeader__title {
    font-size: 3rem;
  }
}
.PageHeader__subtitle {
  font-size: 2rem;
  margin: 0;
  font-weight: 600;
}
@media (max-width: 767px) {
  .PageHeader__subtitle {
    font-size: 1.5rem;
  }
}
.PageHeader__title--center {
  text-align: center;
}
.PageHeader__subtitle--center {
  text-align: center;
}
