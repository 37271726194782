/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EmailCaptureModal {
  padding: 7rem;
  position: relative;
}
@media (max-width: 991px) {
  .EmailCaptureModal {
    min-width: 0;
    padding: 3rem 1rem;
  }
}
.EmailCaptureModal__content {
  margin: auto;
  max-width: 35rem;
}
.EmailCaptureModal__content--collapsed {
  display: none;
}
.EmailCaptureModal__cell {
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
  margin-bottom: 2rem;
}
.EmailCaptureModal__title {
  color: #fff;
  font-weight: 600;
  font-size: 3.5rem;
  padding-bottom: 2rem;
}
.EmailCaptureModal__subtitle {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
}
.EmailCaptureModal__sent {
  margin: auto;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 5rem;
}
.EmailCaptureModal__shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
/********
 KILL ME
*********/
.EmailCaptureModal__cell > div > .EmailInput > div > .Input > label {
  border-radius: 0.4em;
  outline: none;
  margin-bottom: 1.3rem;
  margin-bottom: 0;
}
.EmailCaptureModal__cell > div > .EmailInput > div > .Input > label > input {
  width: 100%;
  outline: none;
  box-shadow: none;
}
