/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PaymentPlanRow {
  background-color: #745a9e;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  background-size: cover;
  background-position: 50%;
  border-radius: 0.6rem;
  margin: 1rem 0;
  text-align: left;
  transition: transform 0.15s;
  border: 0.3rem solid transparent;
  cursor: pointer;
}
.PaymentPlanRow:hover {
  transform: scale(1.1);
}
.PaymentPlanRow--selected {
  border-color: #745a9e;
  background-color: #fff;
  color: #745a9e;
}
.PaymentPlanRow__header {
  display: flex;
  padding: 1.5rem 3rem;
  align-items: center;
  min-height: 10rem;
}
.PaymentPlanRow__details {
  display: none;
}
.PaymentPlanRow__name {
  flex: 1 0 auto;
}
.PaymentPlanRow__price {
  font-size: 3rem;
  font-weight: bold;
  text-align: right;
}
.PaymentPlanRow__price small {
  font-size: 1.5rem;
  position: relative;
  top: -1rem;
}
.PaymentPlanRow__coupon-fullPrice {
  font-size: 1.8rem;
  text-decoration: line-through;
}
.PaymentPlanRow__coupon-fullPrice small {
  font-size: 1rem;
  position: relative;
  top: -0.1rem;
}
.PaymentPlanRow__coupon-discount {
  font-size: 1.4rem;
}
.PaymentPlanRow--colour-darkGreen {
  background-color: #74a524;
}
.PaymentPlanRow--colour-darkGreen.PaymentPlanRow--selected {
  background-color: #fff;
}
.PaymentPlanRow--colour-red {
  background-color: #ff486b;
}
.PaymentPlanRow--colour-red.PaymentPlanRow--selected {
  background-color: #fff;
}
.PaymentPlanRow--colour-yellow {
  background-color: #ffd217;
}
.PaymentPlanRow--colour-yellow.PaymentPlanRow--selected {
  background-color: #fff;
}
