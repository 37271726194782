/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CheckboxBanner {
  cursor: pointer;
  min-height: 11rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  text-align: left;
  font-size: 2rem;
  padding-left: 4rem;
  padding-top: 1rem;
  position: relative;
}
.CheckboxBanner__inputWrapper {
  position: absolute;
  top: 0.7rem;
  left: 1.2rem;
}
.CheckboxBanner__description {
  font-size: 1.2rem;
  margin-top: 1rem;
}
.CheckboxBanner__image {
  float: right;
  margin-left: 2rem;
  margin-top: 2rem;
}
