@import '~ck-core-client/source/globalLess/index.less';
.Input {
	font-size: 1.4rem;
	position: relative;

	select {
		height: 5rem;
	}

	.label {
		display: inline-block;
		width: 40%;
		position: relative;
	}

	.tooltip-icon {
		max-width: 1rem;
	}

	&.valid {
		> div.virtualLabel,
		> label {
			outline: 0.1rem solid @green;
		}
	}

	&.disabled {
		> label {
			margin-bottom: 0;
		}
		> label:after {
			content: ' ';
			z-index: 10;
			display: block;
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.05);
		}
	}

	&.invalid {
		> div.virtualLabel,
		> label {
			outline: 0.1rem solid @red;
		}
	}

	&:focus-within {
		> div.virtualLabel,
		> label {
			outline: 0.1rem solid @ckBlue1;
		}
	}

	div.virtualLabel,
	label {
		background: #fff;
		padding: 0.8rem;
		text-align: left;
		color: @purple2;
		font-weight: 600;
		width: 100%;
		@inputPadding: 1.2rem;
		padding: @inputPadding;
		outline: 0.1rem transparent @red;

		input {
			border: 0;
			display: inline-block;
			width: 60%;
			padding: @inputPadding;
		}

		input:focus {
			outline: none;
		}
	}

	.error {
		padding: 1rem;
		color: @red;
		font-weight: 600;
	}
}
