/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.BadgesTable {
  user-select: none;
}
.BadgesTable > .headingRow {
  background: #e6e0d0;
}
.BadgesTable > .headingRow .react-sweet-progress-circle-outer {
  margin: 0 auto 2rem;
}
.BadgesTable > .headingRow .react-sweet-progress-symbol {
  font-size: 2rem;
}
.BadgesTable > .headingRow > .rowContents > .cell.rarity-Common {
  color: #666666;
}
.BadgesTable > .headingRow > .rowContents > .cell.rarity-Uncommon {
  color: #4a82ff;
}
.BadgesTable > .headingRow > .rowContents > .cell.rarity-Rare {
  color: #67941f;
}
.BadgesTable > .badgeRow {
  display: flex;
  align-items: center;
  background: #fff;
  margin: 2rem 0;
  border-radius: 1rem;
  border-bottom: 0.7rem solid #e6e0d0;
}
.BadgesTable > .badgeRow > .rowHeader,
.BadgesTable > .badgeRow.headingRow .cell {
  font-size: 1.4rem;
  font-weight: 600;
  color: #534468;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.BadgesTable > .badgeRow.headingRow .cell {
  font-weight: bold;
}
.BadgesTable > .badgeRow > .rowHeader {
  width: 20rem;
}
.BadgesTable > .badgeRow > .rowContents {
  display: flex;
  flex: 1;
}
.BadgesTable > .badgeRow > .rowContents > .cell {
  flex: 1 1 20%;
  margin: 2rem 0;
  min-width: 12rem;
  padding: 0.25rem;
  border-right: 0.2rem solid #f9f6ef;
  text-align: center;
}
.BadgesTable > .badgeRow > .rowContents > .cell .BadgeItemTooltip {
  display: inline-block;
}
.BadgesTable > .badgeRow > .rowContents > .cell .Badge {
  margin: 0.3rem;
  transition: all 0.15s;
  transform: scale(1) ;
  cursor: pointer;
}
.BadgesTable > .badgeRow > .rowContents > .cell .Badge:hover {
  transform: scale(1.2) ;
}
