/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PaymentSummaryTable {
  background: #402e59;
  padding: 2rem 5rem;
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
}
.PaymentSummaryTable__table {
  width: 100%;
}
.PaymentSummaryTable__row td:nth-child(2) {
  text-align: right;
}
.PaymentSummaryTable__row td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 0.5;
}
.PaymentSummaryTable__row--total {
  color: #fff;
  font-size: 2rem;
}
.PaymentSummaryTable__row--total td {
  opacity: 1;
}
.PaymentSummaryTable__plan {
  margin-bottom: 2rem;
}
.PaymentSummaryTable__authorisation {
  text-align: center;
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: -3rem;
  margin-top: 2rem;
}
