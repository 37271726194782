/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HereToHelp {
  padding: 2rem;
  font-weight: bold;
  text-align: left;
  background: #f9f6ef;
  color: #095d84;
  border-radius: 0.5rem;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .HereToHelp {
    display: none;
  }
}
.HereToHelp__link {
  color: #095d84;
}
.HereToHelp__link:hover {
  color: #4abcf2;
}
.HereToHelp__option {
  clear: both;
  height: 2.5rem;
  margin-top: 2rem;
  font-size: 1.4rem;
}
.HereToHelp__image {
  float: left;
  margin-right: 2rem;
  margin-top: -0.5rem;
  width: 3rem;
}
