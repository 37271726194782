/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UpgradePlanBanner {
  height: 20rem;
  background: #3873f7;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 991px) {
  .UpgradePlanBanner {
    height: auto;
    padding: 5rem;
  }
}
.UpgradePlanBanner--compact {
  padding: 5rem;
  border-radius: 2rem;
  min-height: 25rem;
  margin-top: 4rem;
}
.UpgradePlanBanner--compact .UpgradePlanBanner__left,
.UpgradePlanBanner--compact .UpgradePlanBanner__right {
  display: none;
}
@media (max-width: 991px) {
  .UpgradePlanBanner--compact {
    height: auto;
  }
}
.UpgradePlanBanner__left,
.UpgradePlanBanner__right {
  background-size: contain;
  background-position: bottom;
  flex: 1 1 20%;
  max-width: 44rem;
  background-repeat: no-repeat;
  height: 100%;
}
@media (max-width: 991px) {
  .UpgradePlanBanner__left,
  .UpgradePlanBanner__right {
    display: none;
  }
}
.UpgradePlanBanner__text {
  margin-right: 5rem;
}
.UpgradePlanBanner__title {
  color: #fff;
  font-weight: 600;
  font-size: 3.5rem;
  padding-bottom: 2rem;
}
.UpgradePlanBanner__subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}
.UpgradePlanBanner__button {
  flex: 1;
}
