/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CouponRemainingUses {
  position: relative;
  bottom: 15px;
  width: 40%;
  margin: 0 auto;
  padding-bottom: 10px;
}
.CouponRemainingUses > .amountText {
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
}
.CouponRemainingUses > .outerBar {
  height: 15px;
  background-color: #f5f0e9;
  border-radius: 4px;
}
.CouponRemainingUses > .outerBar > .innerBar {
  padding: 2px;
  background-color: #e8b827;
  height: 100%;
  border-radius: 3px;
}
