/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PlansAdditionalInfo {
  padding: 1rem;
  color: #2d272d;
  border-radius: 1rem;
  position: relative;
  max-width: 76rem;
  margin: 0 auto;
  z-index: 5;
}
.PlansAdditionalInfo.active {
  background: #fff;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
}
.PlansAdditionalInfo.active > .info {
  display: block;
}
.PlansAdditionalInfo.active > .header {
  font-size: 2rem;
  margin: 2rem;
  display: none;
}
@media (max-width: 767px) {
  .PlansAdditionalInfo.active > .header {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .PlansAdditionalInfo {
    padding: 2rem;
    margin: 3rem;
  }
}
.PlansAdditionalInfo > .header {
  font-weight: 600;
  color: #402e59;
  text-align: center;
  font-size: 1.4rem;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
}
.PlansAdditionalInfo > .header:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .PlansAdditionalInfo > .header {
    font-size: 1.4rem;
  }
}
.PlansAdditionalInfo > .info {
  display: none;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 2rem;
}
.PlansAdditionalInfo > .info > .bullet {
  margin-top: 2rem;
  margin-bottom: 2rem;
  clear: both;
}
.PlansAdditionalInfo > .info > .bullet > .emoji {
  font-size: 2.5rem;
  display: inline-block;
  float: left;
  margin-right: 1rem;
  min-height: 5rem;
}
@media (max-width: 767px) {
  .PlansAdditionalInfo > .info > .bullet > .emoji {
    min-height: auto;
  }
}
.PlansAdditionalInfo > .info > .bullet > .name {
  display: block;
  font-weight: bold;
}
