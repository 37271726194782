/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PaymentPlan {
  cursor: pointer;
  color: #fff;
  padding: 3rem;
  border-radius: 1rem;
  text-align: center;
  position: relative;
  margin: auto;
}
.PaymentPlan > img {
  display: none;
}
@media (max-width: 991px) {
  .PaymentPlan {
    padding: 0;
    height: 12rem;
  }
}
.PaymentPlan.productRoblox {
  background: #f3365a;
}
.PaymentPlan.productRoblox .coupon {
  background: #d3063a;
}
.PaymentPlan.productRoblox .productName {
  background: #d3063a;
}
.PaymentPlan.productBundle {
  background: #745a9e;
}
.PaymentPlan.productBundle .coupon {
  background: #534468;
}
.PaymentPlan.productBundle .productName {
  background: #534468;
}
.PaymentPlan.productBundle .anchor {
  display: none;
}
.PaymentPlan.productModding {
  background: #24a134;
}
.PaymentPlan.productModding .coupon {
  background: #248534;
}
.PaymentPlan.productModding .productName {
  background: #248534;
}
.PaymentPlan .productName {
  width: 100%;
  background: #f3365a;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2rem;
  font-size: 1.6rem;
  color: #ffdc4a;
  font-weight: 600;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
@media (max-width: 991px) {
  .PaymentPlan .productName {
    display: none;
  }
}
.PaymentPlan .anchor {
  position: absolute;
  width: 70%;
  background: #f9cc26;
  top: -3rem;
  padding: .8rem;
  left: 15%;
  font-size: 1.2rem;
  color: #000;
  font-weight: 600;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .PaymentPlan .anchor {
    display: none;
  }
}
.PaymentPlan .name {
  font-size: 2.5rem;
  padding: 1rem;
  margin-top: 5rem;
  font-weight: 600;
}
@media (max-width: 991px) {
  .PaymentPlan .name {
    text-align: left;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin-top: 0;
  }
}
.PaymentPlan .price {
  color: #ffdc4a;
  font-size: 4.5rem;
  padding: 1rem;
  font-weight: 600;
  position: relative;
  margin-left: -2rem;
}
@media (max-width: 991px) {
  .PaymentPlan .price {
    text-align: right;
    position: relative;
    top: 2rem;
    right: 3rem;
  }
}
.PaymentPlan .price .pence {
  font-size: 1.6rem;
  position: absolute;
  top: 2rem;
  margin-left: -0.1rem;
}
.PaymentPlan .equivalentTo {
  color: #ffdc4a;
  font-size: 1.3rem;
  padding: 1rem;
  font-weight: 600;
}
.PaymentPlan .billingDescription {
  font-size: 1.3rem;
  padding: 1rem;
  font-weight: 600;
}
@media (max-width: 991px) {
  .PaymentPlan .billingDescription {
    text-align: left;
    margin-left: 2rem;
    margin-top: -2rem;
    width: 55%;
    font-size: 1.2rem;
  }
}
@media (max-width: 991px) {
  .PaymentPlan .checkoutButton {
    display: none;
  }
}
.fullPrice {
  font-size: 1.7rem;
}
.PaymentPlans__plansWrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .PaymentPlans__plansWrapper {
    display: block;
    margin: 0 auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.PaymentPlans__plansWrapper--rows {
  flex-direction: column;
  max-width: 60rem;
  margin: 0 auto;
}
.Payment__wrapper {
  width: 35rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
@media (max-width: 991px) {
  .Payment__wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    width: 100%;
  }
}
.PaymentPlan__coupon {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.1rem 0.1rem 0.1rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: white;
  height: 9.7rem;
  overflow: hidden;
}
.PaymentPlan__coupon-back {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1199px) {
  .PaymentPlan__coupon-back img {
    display: none;
  }
}
.PaymentPlan--coupon {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.PaymentPlan__was {
  font-weight: bold;
  position: relative;
  font-size: 1.4rem;
  text-align: left;
  margin: 1.5rem;
}
.PaymentPlan__was-price {
  text-decoration: line-through;
  font-size: 2.4rem;
}
.PaymentPlan__discount {
  text-align: right;
  font-weight: 700;
  position: relative;
  color: #ffdc4a;
  font-size: 1.4rem;
  margin: 1.5rem;
}
.PaymentPlan__discount .Coupon__save {
  font-size: 2.4rem;
}
.productRoblox ~ .PaymentPlan__coupon {
  background: #f3365a;
}
.productRoblox ~ .PaymentPlan__coupon .PaymentPlan__was {
  color: #ff95a9;
}
.productBundle ~ .PaymentPlan__coupon {
  background: #534468;
}
.productBundle ~ .PaymentPlan__coupon .PaymentPlan__was {
  color: #9983bb;
}
.productModding ~ .PaymentPlan__coupon {
  background: #248534;
}
.productModding ~ .PaymentPlan__coupon .PaymentPlan__was {
  color: #aae053;
}
.PaymentPlan--translucent {
  opacity: 0.5;
}
.PaymentPlan--highlighted {
  transform: scale(1.1);
}
@media (max-width: 991px) {
  .PaymentPlan--highlighted {
    transform: none;
  }
}
