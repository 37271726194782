/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ProgressIndicator {
  text-align: center;
  display: flex;
  justify-content: center;
}
.ProgressIndicator__dot {
  background: #4a82ff;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  border: 0.7rem solid #4a82ff;
  transition: 0.25s all ease-in-out;
  cursor: pointer;
  position: relative;
}
@media (max-width: 991px) {
  .ProgressIndicator__dot {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
.ProgressIndicator__dot--clickable:hover {
  background: #fff;
  border: 0.25rem solid #4a82ff;
}
.ProgressIndicator__dot--current,
.ProgressIndicator__dot--past {
  background: #fff;
  border: 0.25rem solid #4a82ff;
}
.ProgressIndicator__dot--clickable.ProgressIndicator__dot--past:hover {
  background: #4a82ff;
}
