/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MobilePaymentSummary {
  position: fixed;
  z-index: 200;
  background-color: #fff;
  box-shadow: -0.1rem 0 2rem rgba(0, 0, 0, 0.2);
  width: 100%;
  color: #745a9e;
  text-align: left;
  bottom: 0;
}
@media (min-width: 991px) {
  .MobilePaymentSummary {
    display: none;
  }
}
.MobilePaymentSummary-text > .backButton {
  margin-left: 2rem;
}
.MobilePaymentSummary-initial-info {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  height: 9rem;
}
.MobilePaymentSummary__extra-info {
  font-size: 1.2rem;
  text-align: left;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
  align-items: center;
  height: 0;
  transition: all 0.6s;
  overflow: hidden;
}
.MobilePaymentSummary-text {
  flex: 1;
  border-left: 0.2rem solid #dadada;
  margin: 1rem 2rem;
  padding-left: 2rem;
}
.MobilePaymentSummary__money {
  color: #4a82ff;
  text-align: right;
  font-size: 3.2rem;
  font-weight: 600;
  margin-right: 9rem;
}
@media (max-width: 767px) {
  .MobilePaymentSummary__money {
    margin-right: 2rem;
  }
}
.MobilePaymentSummary__money .MobilePaymentSummary__was {
  display: flex;
  color: #ff486b;
  font-size: 2rem;
}
.MobilePaymentSummary__money .MobilePaymentSummary__was .DisplayMonetaryValue {
  text-decoration: line-through;
  padding-left: 1rem;
}
.MobilePaymentSummary__glitch {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
@media (max-width: 767px) {
  .MobilePaymentSummary__glitch {
    display: none;
  }
}
.MobilePaymentSummary--expanded .MobilePaymentSummary__extra-info {
  height: auto;
}
.MobilePaymentSummary-text__product {
  font-weight: 600;
  font-size: 1.4rem;
}
.MobilePaymentSummary-text__product__first {
  font-size: 1.8rem;
  font-weight: 600;
}
.MobilePaymentSummary__icon {
  transition: 0.3s ease-in-out all;
  margin-left: 2rem;
}
.MobilePaymentSummary__icon--expanded {
  transform: rotate(180deg);
}
.MobilePaymentSummary__features {
  background: #67941f;
  padding: 0.5rem;
  padding-top: 1rem;
}
.MobilePaymentSummary__feature {
  display: flex;
  font-size: 1.5rem;
  margin: 1rem;
}
.MobilePaymentSummary__feature-text {
  color: #fff;
  margin-left: 2rem;
}
.MobilePaymentSummary__reassure {
  padding: 0 2rem;
  height: 7rem;
  background: #fff;
  font-size: 1.4rem;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
