/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Tooltip {
  max-width: 40%;
}
.Tooltip__content {
  overflow: hidden;
}
.Tooltip--theme-white .Tooltip__content {
  background: #fff;
  border-radius: 0.6rem;
}
.Tooltip--theme-white .Tooltip__arrow {
  border-color: #fff;
}
.Tooltip--theme-purple .Tooltip__content {
  background: #745a9e;
  color: #fff;
  border-radius: 0.6rem;
}
.Tooltip--theme-purple .Tooltip__arrow {
  border-color: #745a9e;
}
.Tooltip--theme-blueMaze .Tooltip__content {
  color: #fff;
  border-radius: 0.6rem;
}
.Tooltip--theme-blueMaze .Tooltip__arrow {
  border-color: #402e59;
}
.Tooltip__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin-left: 0;
  margin-right: 0;
}
.Tooltip[data-placement^='left'] {
  margin-right: 5rem;
}
.Tooltip[data-placement^='right'] {
  margin-left: 5rem;
}
.Tooltip[data-placement^='top'] {
  margin-bottom: 5rem;
}
.Tooltip[data-placement^='bottom'] {
  margin-top: 5rem;
}
.Tooltip[data-placement^='left'] .Tooltip__arrow {
  border-width: 2rem 0 2rem 2rem;
  right: -2rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.Tooltip[data-placement^='right'] .Tooltip__arrow {
  border-width: 2rem 2rem 2rem 0;
  left: -2rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.Tooltip[data-placement^='bottom'] .Tooltip__arrow {
  border-width: 0 2rem 2rem 2rem;
  top: -2rem;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.Tooltip[data-placement^='top'] .Tooltip__arrow {
  border-width: 2rem 2rem 0 2rem;
  bottom: -2rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
