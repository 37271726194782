/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UserCard {
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  border-bottom: 0.1rem solid #f9f6ef;
  padding-bottom: 2rem;
  clear: both;
}
.UserCard .avatar {
  float: left;
  width: 14rem;
  height: 14rem;
  border-radius: 100%;
  margin-right: 4rem;
  margin-top: 1rem;
  min-height: 22rem;
}
.UserCard .username {
  font-size: 2.5rem;
}
.UserCard .ClickToCopy {
  display: inline-block;
}
.UserCard .Input {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.UserCard .Input label {
  background: #f9f6ef;
  border-radius: 0.7rem;
}
.UserCard .Input input {
  padding: 1rem;
  border-radius: 0.7rem;
}
.UserCard .Input label {
  width: 100%;
}
.UserCard .RoundButton {
  margin-top: 2rem;
  margin-left: -0.5rem;
  margin-right: 2rem;
}
