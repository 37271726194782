/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CheckoutPage {
  background: #f9f6ef;
  overflow: auto;
  position: relative;
  text-align: center;
}
@media (max-width: 991px) {
  .CheckoutPage {
    z-index: 1;
    margin-bottom: -15rem;
  }
}
.CheckoutPage .PaymentPlans {
  padding-top: 0;
}
.CheckoutPage .PaymentPlans img.pipesLeft {
  display: none;
}
.CheckoutPage .PaymentPlans img.pipesRight {
  display: none;
}
@media (max-width: 991px) {
  .CheckoutPage .PaymentPlans .MinecraftEditionSupportSummary {
    display: none;
  }
}
.CheckoutPage__cloud--left {
  left: 0;
  top: 11rem;
  position: absolute;
}
@media (max-width: 991px) {
  .CheckoutPage__cloud--left {
    display: none;
  }
}
