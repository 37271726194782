/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Icon__tooltip {
  position: absolute;
  background-color: #2d272d;
  color: #fff;
  top: -5rem;
  transform: translateX(-50%) scale(0.7);
  white-space: nowrap;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: 0.1s ease-in all;
  font-size: 1.1rem;
  font-weight: 600;
  visibility: hidden;
}
.Icon__tooltip:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 9px 0;
  border-color: #2d272d transparent transparent;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%);
}
.Icon__tooltip--show {
  transform: translateX(-50%) scale(1);
  visibility: visible;
  top: -7.3rem;
}
