/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CurrentPaymentPlan {
  color: #2d272d;
}
.CurrentPaymentPlan .CurrentPaymentPlanCard {
  font-size: 1.5rem;
  font-weight: 600;
}
.CurrentPaymentPlan .CurrentPaymentPlanCard button {
  font-size: 1.2rem;
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.6rem;
  font-weight: 600;
  border: 0;
  background: #ffdc4a;
  color: #534468;
  cursor: pointer;
}
.CurrentPaymentPlan .CurrentPaymentPlanCard button:hover {
  background: #d59f00;
}
.CurrentPaymentPlanProduct {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.CurrentPaymentPlanProduct div.Image {
  display: inline-block;
}
.CurrentPaymentPlanProduct div.Image img {
  width: 2.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
}
