/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.QandACard {
  display: block;
  color: #2d272d;
}
.QandACard__question {
  font-size: 2rem;
  padding-bottom: 2rem;
  color: #534468;
  cursor: pointer;
}
.QandACard__question:hover {
  text-decoration: underline;
}
.QandACard__answer-wrapper {
  overflow: hidden;
  opacity: 0;
  transition: 0.35s ease-in-out all;
  max-height: 0;
}
.QandACard__answer {
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.QandACard__answer-wrapper--show {
  opacity: 1;
  height: auto;
  max-height: 150rem;
}
