/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Section {
  padding: 4rem;
  min-height: 11rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 1em;
  margin: auto;
}
@media (max-width: 991px) {
  .Section {
    margin: 0 2rem;
  }
}
.Section--noPadding {
  padding: 0;
}
.Section--white {
  background-color: white;
}
.Section--none {
  background-color: transparent;
}
.Section__none {
  border: none;
}
.Section--lightGrey {
  background: #f9f6ef;
}
.Section__border {
  border-radius: 1rem;
}
.Section__border--black {
  border-bottom: 0.7rem solid #2d272d;
}
.Section__border--lightGrey {
  border-bottom: 0.7rem solid #e6e0d0;
}
.Section__pagination {
  text-align: center;
  padding-top: 3rem;
}
