/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Badge {
  display: inline-block;
  border: 0.2em solid #e6e0d0;
  border-radius: 50%;
  background: #f9f6ef;
  position: relative;
  width: 4em;
  height: 4em;
}
.Badge.Badge--locked {
  filter: saturate(0);
  opacity: 0.15;
}
.Badge > .Image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Badge > .Image > .sprite {
  width: 100%;
  height: 100%;
}
.Badge.rarity_Uncommon {
  background: #b6e7ff;
  border-color: #83d6ff;
}
.Badge.rarity_Rare {
  background: #aae053;
  border-color: #95d828;
}
.Badge > .dot {
  width: 0.8rem;
  height: 0.8rem;
  background: #ff486b;
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  border-radius: 50%;
}
