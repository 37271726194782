/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CheckoutStages {
  display: flex;
  background: #f9f6ef;
}
.CheckoutStages__inner {
  color: black;
  text-align: center;
  flex: 1 0 70%;
  position: relative;
  padding-top: 5rem;
}
@media (max-width: 991px) {
  .CheckoutStages__inner {
    padding-top: 5rem;
    min-height: 50rem;
  }
}
.CheckoutStages__inner-maxWidthWrapper {
  max-width: 60rem;
  margin: 0 auto;
}
.CheckoutStages__inner-title {
  font-size: 3rem;
  font-weight: 600;
  color: #095d84;
  padding-top: 2rem;
  z-index: 100;
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 991px) {
  .CheckoutStages__inner-title {
    font-size: 1.8rem;
    padding-top: 3rem;
    padding-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.CheckoutStages__trustpilot {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #191919;
  text-align: center;
  font-size: 1.4rem;
}
.CheckoutStages__trustpilot .scoreName {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  padding-right: 2rem;
}
.CheckoutStages__trustpilot .summary {
  margin-top: 1rem;
  display: inline-block;
  color: #191919;
}
.CheckoutStages__trustpilot .stars {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.CheckoutStages__trustpilot .logo {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.CheckoutStages__inner-subtitle {
  font-size: 1.6em;
  font-weight: 600;
  color: #095d84;
  padding-bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  position: relative;
}
@media (max-width: 991px) {
  .CheckoutStages__inner-subtitle {
    display: none;
  }
}
.CheckoutStages__TileChoice#modding > .name,
.CheckoutStages__TileChoice#roblox > .name {
  display: none;
}
.CheckoutStages__TileChoice#family .description,
.CheckoutStages__TileChoice#family .name {
  color: #095d84;
}
.CheckoutStages__feedback {
  max-width: 60rem;
  margin: 0 auto;
}
.CheckoutStages__feedback label {
  width: 100%;
}
.CheckoutStages__feedback textarea {
  width: 100%;
  padding: 1rem;
  border: 0;
}
.CheckoutStages__feedback p {
  text-align: left;
  font-size: 1.4rem;
  padding: 1rem;
  font-weight: 600;
  margin-top: 5rem;
}
.CheckoutStages__sidebar {
  background: #534468;
  color: #fff;
  font-size: 1.6em;
  flex: 0 0 30%;
  min-height: 144rem;
  margin-bottom: 4rem;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
}
@media (max-width: 991px) {
  .CheckoutStages__sidebar {
    float: none;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 2rem;
    min-height: 0;
    display: none;
  }
}
.CheckoutStages__sidebar-header {
  font-weight: bold;
  font-size: 1.4em;
  text-align: left;
  padding: 3rem;
}
.CheckoutStages--theme-compact {
  padding-top: 5rem;
  justify-content: center;
}
.CheckoutStages--theme-compact .CheckoutStages__inner,
.CheckoutStages--theme-compact .CheckoutStages__sidebar {
  flex: 0 1 50rem;
}
.CheckoutStages--theme-compact .CheckoutStages__sidebar {
  margin-top: 8rem;
  background: #fff;
  min-height: 0;
}
.CheckoutStages--theme-compact .CheckoutStages__inner {
  margin-right: 5rem;
}
@media (max-width: 991px) {
  .CheckoutStages--theme-compact .CheckoutStages__inner {
    margin-right: 0;
  }
}
.CheckoutStages--theme-compact .CheckoutStages__inner .CheckoutStages__confirmationWrapper-title {
  color: #095d84;
}
.CheckoutStages--theme-compact .CheckoutStages__sidebar-header {
  font-size: 2.4rem;
  color: #095d84;
  font-weight: 600;
}
.CheckoutStages--theme-compact .PaymentSummaryTable {
  background: #fff;
  color: #095d84;
}
.CheckoutStages--theme-compact .PaymentSummaryTable td {
  opacity: 1;
}
.CheckoutStages--theme-compact .PaymentSummaryTable__table {
  border-top: 0.1rem solid #e6e0d0;
}
.CheckoutStages--theme-compact .PaymentSummaryTable__row--total {
  color: #67941f;
  font-weight: 600;
}
.CheckoutStages--theme-compact .PaymentSummaryTable__row--total td:nth-child(2) {
  font-size: 3rem;
}
.CheckoutStages--theme-compact .CreditCardForm__secureInfo {
  margin-bottom: 0;
}
.CheckoutStages--theme-compact .CheckoutStages__confirmationWrapper {
  color: #67941f;
  margin: 4rem;
}
.CheckoutStages__sidebar-choiceWrapper.current {
  background-color: #402e59;
}
.CheckoutStages__sidebar-choiceWrapper.current > .choice {
  border-top: 0;
}
.CheckoutStages__sidebar-choiceWrapper.current > .choice > .index {
  color: #ffdc4a;
  border: 0.3rem solid #ffdc4a;
}
.CheckoutStages__sidebar-choiceWrapper > .choice {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0 auto;
  width: 80%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}
.CheckoutStages__sidebar-choiceWrapper > .choice button {
  margin-top: 2rem;
  margin-left: 3rem;
}
.CheckoutStages__sidebar-choiceWrapper > .choice > div {
  display: inline-block;
}
.CheckoutStages__sidebar-choiceWrapper > .choice > .index {
  width: 5%;
  font-size: 150%;
  position: relative;
  right: 1.2rem;
  top: 0.2rem;
  color: white;
  border: 0.3rem solid white;
  border-radius: 100%;
  padding-top: 0.2rem;
  padding-left: 1.1rem;
  width: 4rem;
  height: 4rem;
  font-family: MONOSPACE;
}
.CheckoutStages__sidebar-choiceWrapper > .choice > .rightResult {
  background: #402e59;
  width: 67%;
  border-radius: 3rem;
  padding: 0.5rem;
  text-align: center;
}
.CheckoutStages__sidebar-choiceWrapper > .choice > .rightResult > .RoundButton {
  vertical-align: middle;
}
.CheckoutStages__banner {
  margin: 3rem;
}
.CheckoutStages__confirmationWrapper {
  text-align: center;
  margin-top: 5rem;
}
.CheckoutStages__confirmationWrapper-title {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 4rem;
}
.CheckoutStages__confirmationWrapper-successEmail {
  margin-top: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  font-weight: 600;
  font-size: 1.4rem;
}
.CheckoutStages__topMessage {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 4rem;
  margin-bottom: 3rem;
}
.CheckoutStages__navigation-bar {
  position: fixed;
  height: 5rem;
  z-index: 200;
  background-color: #745a9e;
  width: 100%;
  color: white;
  display: table;
  text-align: left;
}
@media (min-width: 991px) {
  .CheckoutStages__navigation-bar {
    display: none;
  }
}
.CheckoutStages__navigation-bar-text {
  display: table-cell;
  vertical-align: middle;
}
.CheckoutStages__navigation-bar-text > .backButton {
  margin-left: 2rem;
}
@media (max-width: 991px) {
  .CheckoutStages__navigation-back-button {
    padding-top: 0;
    display: none;
  }
}
