/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PaymentPlansWrapper--grey {
  background-color: #f9f6ef;
}
.PaymentPlans {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 2rem;
}
@media (max-width: 991px) {
  .PaymentPlans {
    padding: 3rem 0 2rem;
    line-height: 1.3;
  }
}
.PaymentPlans.promo.holidaysale {
  background-color: #ff486b;
}
.PaymentPlans.promo.holidaysale .title,
.PaymentPlans.promo.holidaysale .subtitle,
.PaymentPlans.promo.holidaysale .PaymentPlans__SizeTabsWrapper__info,
.PaymentPlans.promo.holidaysale .PlansAdditionalInfo > .header {
  color: #fff;
}
.PaymentPlans.promo.holidaysale .membershipSummary .checklistItem,
.PaymentPlans.promo.holidaysale .membershipSummary .header {
  color: #fff !important;
}
.PaymentPlans.promo.holidaysale .packagesWrapper {
  background: #ffffff00;
}
.PaymentPlans.promo.holidaysale .CheckoutStages {
  background: inherit;
}
.PaymentPlans.promo.easter .PaymentPlans__topRightImagePromo {
  top: 9rem;
}
.PaymentPlans.promo.blackfriday {
  background-color: #221f25;
}
.PaymentPlans.promo.blackfriday #Tab-individual.Tab--selected {
  background: #314bff;
}
.PaymentPlans.promo.blackfriday #Tab-family.Tab--selected {
  background: #314bff;
}
.PaymentPlans.promo.blackfriday .title,
.PaymentPlans.promo.blackfriday .subtitle,
.PaymentPlans.promo.blackfriday .PaymentPlans__SizeTabsWrapper__info,
.PaymentPlans.promo.blackfriday .PlansAdditionalInfo > .header {
  color: #fff;
}
.PaymentPlans.promo.blackfriday .PaymentPlans__topRightImagePromo {
  top: 0;
  right: -7rem;
}
@media (max-width: 991px) {
  .PaymentPlans.promo.blackfriday .giftingInfo .copy {
    margin-top: -1rem;
    font-size: 1.2rem;
  }
}
.PaymentPlans.promo.blackfriday .membershipSummary .checklistItem,
.PaymentPlans.promo.blackfriday .membershipSummary .header {
  color: #fff !important;
}
.PaymentPlans.promo.blackfriday .packagesWrapper {
  background: #ffffff00;
}
@media (max-width: 991px) {
  .PaymentPlans.promo.blackfriday .blackFridayMessage {
    display: none;
  }
}
.PaymentPlans.promo.blackfriday .blackFridayMessage .copy {
  font-size: 1.3rem;
}
.PaymentPlans > div {
  position: relative;
}
.PaymentPlans .PaymentPlans__ProductTabsWrapper {
  z-index: 100;
  margin-top: -9.3rem;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__ProductTabsWrapper {
    margin-top: -8rem;
  }
}
.PaymentPlans .PaymentPlans__ProductTabsWrapper .Tab--theme-formal {
  background: #7e735b;
  color: #fff;
  border-color: #564d3a;
}
.PaymentPlans .PaymentPlans__ProductTabsWrapper .Tab--theme-formal.Tab--selected {
  background: #f9f6ef;
  color: #000;
}
.PaymentPlans .PaymentPlans__ProductTabsWrapper #Tab-modding {
  border-radius: 0;
  border-bottom: none;
  border-top-left-radius: 0.5rem;
}
.PaymentPlans .PaymentPlans__ProductTabsWrapper #Tab-bundle {
  border-radius: 0;
  border-bottom: none;
  border-top-right-radius: 0.5rem;
}
.PaymentPlans .PaymentPlans__SizeTabsWrapper {
  z-index: 100;
}
.PaymentPlans .PaymentPlans__SizeTabsWrapper #Tab-individual {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.PaymentPlans .PaymentPlans__SizeTabsWrapper #Tab-family {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.PaymentPlans .Tab--selected {
  border: 0;
}
.PaymentPlans .Tab--theme-formal {
  font-size: 1.4rem;
}
.PaymentPlans.promo.summer {
  background-repeat: no-repeat;
  background-position: left top;
  background-color: #b6e7ff;
  padding-bottom: 4rem;
}
.PaymentPlans.promo.summer .title {
  color: #007dba;
  margin: 0 auto;
  max-width: 70rem;
}
.PaymentPlans.promo.summer .subtitle {
  color: #007dba;
}
.PaymentPlans.promo.summer .PaymentPlans__SizeTabsWrapper .PaymentPlans__SizeTabsWrapper__header {
  color: #007dba;
}
.PaymentPlans.promo.summer .PaymentPlans__SizeTabsWrapper .PaymentPlans__SizeTabsWrapper__info {
  color: #007dba;
}
.PaymentPlans.promo.summer .packagesWrapper {
  background: #ffffff00;
}
.PaymentPlans.promo.summer .CheckoutStages {
  background: inherit;
}
.PaymentPlans .title {
  font-size: 3.2rem;
  font-weight: 600;
  color: #095d84;
  padding-top: 2rem;
  z-index: 100;
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 0;
  margin-top: 0;
}
@media (max-width: 991px) {
  .PaymentPlans .title {
    font-size: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.PaymentPlans .subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #095d84;
  padding: 0rem 3rem 0;
  z-index: 100;
  position: relative;
}
@media (max-width: 991px) {
  .PaymentPlans .subtitle {
    padding: 0 7rem;
  }
}
.PaymentPlans .pipesLeft {
  left: 0;
  top: 4rem;
  display: block;
  position: absolute;
}
@media (max-width: 991px) {
  .PaymentPlans .pipesLeft {
    top: 3rem;
    left: -26rem;
  }
}
.PaymentPlans .pipesRight {
  right: 0;
  top: 9rem;
  display: block;
  position: absolute;
}
@media (max-width: 991px) {
  .PaymentPlans .pipesRight {
    display: none;
  }
}
.PaymentPlans .packagesWrapper {
  background: #f9f6ef;
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 8rem;
  z-index: 100;
}
@media (max-width: 991px) {
  .PaymentPlans .packagesWrapper {
    width: 100%;
  }
}
.PaymentPlans .bottomBackground {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  height: 44rem;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
.PaymentPlans .bottomInfo {
  background: #fff;
  padding: 2rem;
  width: 80%;
  margin: 0 auto;
  border-radius: 0.8rem;
  margin-top: 9rem;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  z-index: 100;
  max-width: 110rem;
}
@media (max-width: 991px) {
  .PaymentPlans .bottomInfo {
    margin-top: 0;
  }
}
.PaymentPlans .PaymentPlans__topLeftImage {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topLeftImage {
    left: -4rem;
  }
}
.PaymentPlans .PaymentPlans__topLeftImagePromo {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topLeftImagePromo {
    left: -19rem;
  }
}
.PaymentPlans .PaymentPlans__topRightImage {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topRightImage {
    right: -13rem;
  }
}
.PaymentPlans .PaymentPlans__topRightImagePromo {
  right: -7rem;
  top: 18rem;
  display: block;
  position: absolute;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topRightImagePromo {
    display: none;
  }
}
.PaymentPlans .PaymentPlans__topLeftImagePromoSummer {
  position: absolute;
  left: 0;
  top: 0;
  width: 17rem;
  overflow: hidden;
  pointer-events: none;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topLeftImagePromoSummer {
    display: none;
  }
}
.PaymentPlans .PaymentPlans__topRightImagePromoSummer {
  right: 0;
  top: 4rem;
  display: block;
  position: absolute;
  overflow: hidden;
  width: 36rem;
  height: 48rem;
  background-position: top right;
  pointer-events: none;
}
@media (max-width: 991px) {
  .PaymentPlans .PaymentPlans__topRightImagePromoSummer {
    height: 12.21rem;
    background-size: 264%;
    background-repeat: no-repeat;
    right: -6rem;
    top: 13rem;
  }
}
.PaymentPlansWrapper {
  text-align: center;
}
.PaymentPlansWrapper .bottomInfo {
  background: #f9f6ef;
}
.PaymentPlansWrapper .upgradePricingMessage {
  color: black;
  max-width: 76rem;
  border-radius: 1rem;
  background: #fff;
  margin: 5rem auto;
  font-weight: 600;
  padding: 2rem;
}
.PaymentPlansWrapper .checkoutFaq {
  max-width: 76rem;
  border-radius: 1rem;
  background: #fff;
  margin: 5rem auto;
  overflow: hidden;
  z-index: 5;
  text-align: left;
  padding: 3rem;
  font-weight: 600;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .checkoutFaq {
    margin: 4rem 3rem;
  }
}
.PaymentPlansWrapper .checkoutFaq .faqTitle {
  font-size: 2.4rem;
  color: #095d84;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .checkoutFaq .faqTitle {
    font-size: 1.8rem;
  }
}
.PaymentPlansWrapper .checkoutFaq .QandACard__question {
  font-size: 1.6rem;
  color: #095d84;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .checkoutFaq .QandACard__question {
    font-size: 1.4rem;
  }
}
.PaymentPlansWrapper .checkoutFaq .QandACard__answer {
  font-size: 1.3rem;
  color: #095d84;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .checkoutFaq .QandACard__answer {
    font-size: 1.1rem;
  }
}
.PaymentPlansWrapper .membershipSummary {
  position: relative;
  max-width: 76rem;
  height: 24.5rem;
  border-radius: 1rem;
  margin: 5rem auto;
  overflow: hidden;
  z-index: 5;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .membershipSummary {
    margin: 4rem 3rem;
    height: auto;
  }
}
.PaymentPlansWrapper .membershipSummary .background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .membershipSummary .background {
    opacity: 0.1;
  }
}
.PaymentPlansWrapper .membershipSummary .header {
  font-size: 2.4rem;
  margin-top: 2rem;
  color: #095d84;
  font-weight: 600;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .membershipSummary .header {
    font-size: 1.7rem;
  }
}
.PaymentPlansWrapper .membershipSummary .copy {
  text-align: left;
  margin: 2rem;
  color: #095d84;
}
.PaymentPlansWrapper .membershipSummary .copy .checklistRow {
  display: block;
  margin: 0;
  width: 50%;
  padding: 1rem;
  float: left;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .membershipSummary .copy .checklistRow {
    width: 100%;
  }
}
.PaymentPlansWrapper .membershipSummary .copy .checklistRow .icon {
  display: inline-block;
}
.PaymentPlansWrapper .membershipSummary .copy .checklistRow .checklistItem {
  display: inline-block;
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 1.2rem;
  color: #000;
}
.PaymentPlansWrapper .giftingInfo {
  position: relative;
  max-width: 76rem;
  height: 18.5rem;
  border-radius: 1rem;
  background: #fff;
  margin: 5rem auto;
  overflow: hidden;
  z-index: 5;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .giftingInfo {
    margin: 4rem 3rem;
    height: auto;
  }
}
.PaymentPlansWrapper .giftingInfo > .Image {
  position: absolute;
  top: 0;
  left: 0;
}
.PaymentPlansWrapper .giftingInfo > .Image > .promo-small {
  display: none;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .giftingInfo > .Image > .promo-large {
    display: none;
  }
  .PaymentPlansWrapper .giftingInfo > .Image > .promo-small {
    display: block;
    opacity: 0.5;
  }
}
.PaymentPlansWrapper .giftingInfo > .textWrapper {
  position: relative;
  top: 0;
  color: #095d84;
  width: 35rem;
  margin: 0 auto;
  font-weight: 600;
  display: inline-grid;
  height: 18.5rem;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .giftingInfo > .textWrapper {
    width: 29rem;
    padding-top: 0;
    height: auto;
  }
}
.PaymentPlansWrapper .giftingInfo > .textWrapper > .header {
  font-size: 2.4rem;
  margin-top: 2rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .giftingInfo > .textWrapper > .header {
    font-size: 1.7rem;
    margin-top: 1rem;
  }
}
.PaymentPlansWrapper .giftingInfo > .textWrapper > .copy {
  font-size: 1.7rem;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .PaymentPlansWrapper .giftingInfo > .textWrapper > .copy {
    font-size: 1.4rem;
    margin-top: 0;
  }
}
.PaymentPlansWrapper > img {
  display: none;
}
.PaymentPlans__SizeTabsWrapper {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.PaymentPlans__SizeTabsWrapper .header {
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
  color: #745a9e;
  margin-bottom: 0.7rem;
}
.PaymentPlans__SizeTabsWrapper .info {
  font-size: 1.2rem;
  font-weight: 600;
  color: #745a9e;
  margin-top: 0.5rem;
}
.PaymentPlans__SizeTabsWrapper {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.PaymentPlans__SizeTabsWrapper__header {
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 0.7rem;
}
.PaymentPlans__SizeTabsWrapper__info {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 0.5rem;
}
.PaymentPlans__SizeTabsWrapper--purple {
  color: #402e59;
}
.PaymentPlans__SizeTabsWrapper--white {
  color: white;
}
.PaymentPlansWrapper .subtitle {
  margin-bottom: 3rem;
}
