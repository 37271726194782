/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.BadgesTooltipContent__intro {
  font-size: 1.4rem;
}
.BadgesTooltipContent__header {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.BadgesTooltipContent__header__title {
  text-align: center;
  text-transform: capitalize;
}
.BadgesTooltipContent__content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.BadgesTooltipContent__progressionInfo {
  margin: 1rem;
}
.BadgesTooltipContent__progressionKey__wrapper {
  width: 7rem;
}
.BadgesTooltipContent__progressionKey {
  margin: 1rem 0 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  font-weight: bold;
  text-align: center;
}
.BadgesTooltipContent__description {
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 2rem;
}
.BadgesTooltipContent__progression {
  align-items: center;
  display: flex;
  flex-direction: column;
}
