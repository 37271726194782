/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Table {
  position: relative;
  margin-bottom: 5rem;
}
.Table__container {
  margin: auto;
  font-weight: 600;
}
.Table__filter {
  position: absolute;
  top: -3rem;
  right: 2rem;
}
.Table__row-action {
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}
.Table__filter-input {
  outline: none;
  margin-left: 1.5rem;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0.5rem;
  border: 1px solid #f2eee5;
}
.Table__container > .ReactTable .rt-tbody .rt-td {
  border: none;
  padding-right: 1.3rem;
}
.Table__container > .ReactTable {
  border: none;
}
.Table__container .ReactTable .rt-table {
  border-radius: 1rem;
}
.Table__container > .ReactTable .rt-thead .rt-tr {
  padding: 1.3rem;
  text-align: left;
  font-weight: 600;
}
.Table__container .rt-tr-group .rt-tr {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}
.Table__container > .ReactTable .rt-td {
  padding: 0;
  padding-top: 12px;
  padding-bottom: 5px;
}
.Table__container > .ReactTable > .rt-noData {
  display: block;
  z-index: 100000;
  background: none;
  pointer-events: none;
  padding: 20px;
  margin-top: 3rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}
.rt-tbody {
  background-color: white;
}
.rt-thead {
  background-color: #745a9e;
  color: #fff;
}
.Table__container > .ReactTable .-pagination {
  padding-top: 2rem;
  border-top: none;
  box-shadow: none;
}
.rt-th {
  outline: none;
}
.Table__container > .ReactTable .rt-thead .rt-th.-sort-asc,
.Table__container > .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.9);
}
.Table__container > .ReactTable .rt-thead .rt-th.-sort-desc,
.Table__container > .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.9);
}
